import React from "react";
import styles from './styles.module.css';
import { useState } from "react";

const FunctionalTemplate = ({ data }) => {
    const [selectedColor, setSelectedColor] = useState("#000000");

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    console.log("Data in FunctionalTemplate:", data);
    console.log("Personal Info Data:", data.personal);

    if (!data.personal) return null;

    return (
        <>
            {/* Color Picker */}
            <div className={`${styles.colorSelector} no-print`}>
                <button className={styles.red} onClick={() => handleColorChange("#ff6347")}></button>
                <button className={styles.blue} onClick={() => handleColorChange("#001367")}></button>
                <button className={styles.voilet} onClick={() => handleColorChange("#710077")}></button>
                <button className={styles.grey} onClick={() => handleColorChange("#545454")}></button>
            </div>

            <div
                className={styles.functionalTemplateContainer}
                style={{ "--main-color": selectedColor }}
            >
                {/* Header Section */}
                <div className={styles.header}>
                    <img
                        src={`http://localhost:5000/uploads/${data.personal?.imagePath}`}
                        alt="Profile"
                        className={styles.profileImage}
                        crossOrigin="anonymous"
                    />
                    <div className={styles.personalDetails}>
                        <h1 className={styles.head}>{data.personal?.firstName} {data.personal?.lastName}</h1>
                        <h3 className={styles.profession}>{data.education[0]?.degree} {data.education[0]?.discipline}</h3>
                        <p className={styles.description}>{data.personal.description}</p>
                    </div>
                </div>

                {/* Main Content */}
                <div className={styles.content}>
                    {/* Skills Section */}
                    <section className={styles.skillsSection}>
                        <h2>Key Skills</h2>
                        <ul className={styles.skillsList}>
                            {data.skill.map((ski, index) => (
                                <li key={index}>
                                    <span className={styles.skillName}>{ski.skill}</span> 
                                    - {ski.level}
                                </li>
                            ))}
                        </ul>
                    </section>

                    {/* Education Section */}
                    <section className={styles.educationSection}>
                        <h2>Education</h2>
                        <ul>
                            {data.education.map((edu, index) => (
                                <li key={index}>
                                    <h3>{edu.institution}</h3>
                                    <p>{edu.degree} in {edu.discipline}</p>
                                    <p>
                                        {new Date(edu.startDate).toLocaleDateString()} -{" "}
                                        {new Date(edu.endDate).toLocaleDateString()}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </section>

                    {/* Experience Section */}
                    <section className={styles.experienceSection}>
                        <h2>Professional Experience</h2>
                        <ul>
                            {data.experience.map((exp, index) => (
                                <li key={index}>
                                    <h3>{exp.position} - {exp.company}</h3>
                                    <p>
                                        {new Date(exp.startDate).toLocaleDateString()} -{" "}
                                        {new Date(exp.endDate).toLocaleDateString()}
                                    </p>
                                    <p>{exp.description}</p>
                                </li>
                            ))}
                        </ul>
                    </section>

                    {/* Awards Section */}
                    <section className={styles.awardsSection}>
                        <h2>Awards & Achievements</h2>
                        <ul>
                            {data.awards.map((awa, index) => (
                                <li key={index}>
                                    <h3>{awa.title}</h3>
                                    <p>
                                        Awarded by {awa.awardby} on{" "}
                                        {new Date(awa.date).toLocaleDateString()}
                                    </p>
                                    <p>{awa.description}</p>
                                </li>
                            ))}
                        </ul>
                    </section>

                    {/* Activities Section */}
                    <section className={styles.activitiesSection}>
                        <h2>Activities</h2>
                        <ul>
                            {data.activities.map((act, index) => (
                                <li key={index}>
                                    <h3>{act.title}</h3>
                                    <p>{act.description}</p>
                                </li>
                            ))}
                        </ul>
                    </section>
                </div>
            </div>
        </>
    );
};

export default FunctionalTemplate;
