import React from 'react'
import styles from './styles.module.css'
import {Link,useNavigate} from 'react-router-dom'
import { useState } from 'react';
import axios from 'axios';


function Contact() {
  const handleLogout = () => {
		localStorage.removeItem("token");
		navigate("/login");
	};
	const[data,setdata]=useState({
          doornumber: "4356",
          plotnumber: "678",
          street: "Navasakthi nagar",
          area: "nava",
          location: "kamaraj salai",
          city: "pondy",
          state:"Pondy",
          country: "India",
          landmark:"VVP Arch",
          pincode: "605009",
          phone: "8989687889", 
          github: "github.com/suba/", 
          linkedin: "linked.com/subasaravan" 

    });
     const[error,setError]=useState('')
     const navigate=useNavigate();


    const handleChange=({currentTarget:input})=>{
        setdata({...data,[input.name]:input.value})
    };
   
    const handleSubmit=async(e)=>{
       console.log("slkdfs");  
       e.preventDefault();
       console.log(data);
       try{
            const token=localStorage.getItem('token');
            const config={
                headers:{
                    "x-auth-token": token 
                }
            };
           const url="http://localhost:5000/api/contact/contactinfo";
           await axios.post(url,data,config);
           alert("data saved successfully")
           navigate('/skill')
        //    console.log(res.message);
       }
       catch(error){
           if(error.response && error.response.status>=400 && error.response.status<=500){
               setError(error.response.data.message);
           }
       }
    }

  return (
    <>
        <div className={styles.main_container}>
            <nav className={styles.navbar}>
                <h1>Resume Builder</h1>
                <button className={styles.white_btn} onClick={handleLogout}>
                    Logout
                </button>
            </nav>
        </div>
        <div className={styles.signup_container}>
         <div className={styles.signup_form_container}>
           <div className={styles.left}>
            <h1>Contact Details</h1>
             <Link to="/login">
             {/* <button type='button' className={styles.white_btn}>
                Sign In
             </button> */}
             </Link>
           </div>
           <div className={styles.right}>
            <form className={styles.form_container} onSubmit={handleSubmit}>
              <h1>Contact Details</h1>
               <input
                  type='text'
                  placeholder='Door Number'
                  name="doornumber"
                  value={data.doornumber}
                  onChange={handleChange}
                  required
                  className={styles.input}
                  maxLength="4"
                  pattern="\d{4}"
              />
			        <input
                  type='text'
                  placeholder='Plot Number'
                  name="plotnumber"
                  value={data.plotnumber}
                  onChange={handleChange}
                  required
                  className={styles.input}
                  maxLength="3"
                  pattern="\d{3}"
              />
			  
			   <input
                  type='text'
                  placeholder='Street Name'
                  name="street"
                  value={data.street}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
			   <input
                  type='text'
                  placeholder='Area Name'
                  name="area"
                  value={data.area}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
			  
			   <input
                  type='text'
                  placeholder='Location'
                  name="location"
                  value={data.location}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
			        <input
                  type='text'
                  placeholder='City'
                  name="city"
                  value={data.city}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
              <input
                  type='text'
                  placeholder='State'
                  name="state"
                  value={data.state}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
			        <input
                  type='text'
                  placeholder='Country'
                  name="country"
                  value={data.country}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
               <input
                  type='text'
                  placeholder='Landmark'
                  name="landmark"
                  value={data.landmark}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
			        <input
                  type='text'
                  placeholder='Pincode'
                  name="pincode"
                  value={data.pincode}
                  onChange={handleChange}
                  required
                  className={styles.input}
                  maxLength="6"
                  pattern="\d{6}"
              />
              <input
               type='text'
                placeholder='Phone Number' 
                name="phone" value={data.phone} 
                onChange={handleChange} 
                required 
                className={styles.input} 
                pattern="\d{10}" />

              <input
               type='text'
                placeholder='GitHub Link'
                name="github" 
                value={data.github}   
                onChange={handleChange} 
                required 
                className={styles.input} 
                />

              <input 
              type='text' 
              placeholder='LinkedIn Link' 
              name="linkedin" 
              value={data.linkedin} 
              onChange={handleChange} 
              required 
              className={styles.input} 
              />

			   <br></br>
              {error && <div className={styles.error_msg}>{error}</div>}
              <button type="submit" className={styles.green_btn}>
                Next
              </button>
            </form>
           </div>
         </div>
     </div>
    </>
        
  )
}

export default Contact