import React from "react";
import styles from './styles.module.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Awards=()=>{
    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    const [awards,setAwards] = useState([{title:'cutiepie', awardby:'Team Leader',date:'2021-02-20',description:"sample" }]);
    const [error, setError] = useState('');
    const navigate = useNavigate('');

   
    const handleAwardChange = (index, e) => {
        const updatedAwards = [...awards];
        updatedAwards[index][e.target.name] = e.target.value;
        setAwards(updatedAwards);
    };

  
    const handleAddAwards = () => {
        setAwards([...awards, {title: '', awardby: ' ', date: ' ',description:"" }]);
    };

    const handleRemoveAwards = (index) => {
        const updatedAwards = awards.filter((_, i) => i !== index);
        setAwards(updatedAwards);
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("gfgfhfgf")
        console.log(awards)
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    "x-auth-token": token
                }
            };
            const url ="http://localhost:5000/api/awards/awardsinfo";
            await axios.post(url, { awards }, config);
            alert("Awards Details saved successfully");
            navigate('/preview');
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                setError(error.response.data.message);
            }
        }
    };

    return (
        <>
            <div className={styles.main_container}>
                <nav className={styles.navbar}>
                    <h1>Resume Builder</h1>
                    <button className={styles.white_btn} onClick={handleLogout}>
                        Logout
                    </button>
                </nav>
            </div>
            <div className={styles.signup_container}>
                <div className={styles.left}></div>
                <form onSubmit={handleSubmit} className={styles.form_container}>
                    <h1>Add Your Awards List</h1>
                    {awards.map((award, index) => (
                        <div key={index} className={styles.awards_container}>
                            <input
                                type="text"
                                name="title"
                                placeholder="Title of the Award"
                                value={award.title}
                                onChange={(e) => handleAwardChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="text"
                                name="awardby"
                                placeholder="Awarded By"
                                value={award.awardby}
                                onChange={(e) =>  handleAwardChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="date"
                                name="date"
                                placeholder="Date"
                                value={award.date}
                                onChange={(e) =>  handleAwardChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <textarea 
                            name="description" 
                            type="text"
                            placeholder="Description"
                            value={award.description}
                            onChange={(e) =>  handleAwardChange(index, e)}
                            required
                            className={styles.input}
                            />
                            
                            <button
                                type="button"
                                onClick={() => handleRemoveAwards(index)}
                                className={styles.delete_btn}
                            >Delete</button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddAwards} className={styles.add_btn}>
                        Add More Awards
                    </button>
                    {error && <div className={styles.error_msg}>{error}</div>}
                    <button type="submit" className={styles.submit_btn}>
                        Save Awards
                    </button>
                </form>
            </div>
        </>
    );
}
export default Awards;
