import styles from './styles.module.css'
import {Link,useNavigate} from 'react-router-dom'
import { useState } from 'react';
import axios from 'axios';

 const Main=()=>{
    const handleLogout = () => {
		localStorage.removeItem("token");
		navigate("/login");
	};
	const[data,setdata]=useState({
        firstName:"subathra",
        dob: "2003-03-07",
        gender: "Female",
        nationality: "Indian",
        description:"About yourself in 10 lines"
    });
    const [image, setImage] = useState(null);
     const[error,setError]=useState('')
     const navigate=useNavigate();


    const handleChange=({currentTarget:input})=>{
        setdata({...data,[input.name]:input.value})
    };
    const handleFileChange = (e) => {
        setImage(e.target.files[0]);
    };
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("firstName", data.firstName);
        formData.append("dob", data.dob);
        formData.append("gender", data.gender);
        formData.append("description", data.description);
        formData.append("nationality", data.nationality);
        if (image) formData.append("profilePicture", image);

        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    "x-auth-token": token,
                    "Content-Type": "multipart/form-data"
                }
            };
            const url = "http://localhost:5000/api/personal/personalinfo";
            await axios.post(url, formData, config);
            alert("Data and image saved successfully");
            navigate('/contact');
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                setError(error.response.data.message);
            }
        }
    };

	return (
		<>
		<div className={styles.main_container}>
			<nav className={styles.navbar}>
				<h1>Resume Builder</h1>
				<button className={styles.white_btn} onClick={handleLogout}>
					Logout
				</button>
			</nav>
		</div>
		<div className={styles.signup_container}>
         <div className={styles.signup_form_container}>
           <div className={styles.left}>
            <h1>Personal Info</h1>
             <Link to="/login">
             {/* <button type='button' className={styles.white_btn}>
                Sign In
             </button> */}
             </Link>
           </div>
           <div className={styles.right}>
            <form className={styles.form_container} onSubmit={handleSubmit}>
              <h1>Personal Details</h1>
              <input
                  type='text'
                  placeholder='Name'
                  name="firstName"
                  value={data.firstName}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
              <input
                  type='date'
                  placeholder='Date of Birth'
                  name="dob"
                  value={data.dob}
                  onChange={handleChange}
                  required
                  className={styles.input}/><br></br>
                  <label className={styles.gender}>Gender</label>
              <input
                  type='radio'
                  name="gender"
                  value="Male"
                  checked={data.gender === "Male"}
                  onChange={handleChange}
                  required
                  className={styles.radio_input}
              /><label>Male</label>
			  <input
                  type='radio'
                  name="gender"
                  value="Female"
                  checked={data.gender ==="Female"}
                  onChange={handleChange}
                  required
                  className={styles.radio_input}
              /><label>Female</label><br></br>
               <input
                  type='text'
                  placeholder='Nationality'
                  name="nationality"
                  value={data.nationality}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
               <input
                type="file"
                name="profilePicture"
                value={data.profilePicture}
                onChange={handleFileChange}
                className={styles.input}
            />
            <textarea 
                            name="description" 
                            type="text"
                            placeholder="About Yourself"
                            value={data.description}
                            onChange={handleChange}
                            required
                            className={styles.input}
                            />
			   <br></br>
              {error && <div className={styles.error_msg}>{error}</div>}
              <button type="submit" className={styles.green_btn}>
                Next
              </button>
            </form>
           </div>
         </div>
     </div>
		</>

	);
 }
 export default Main