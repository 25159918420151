import React from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import bg from '../assests/bg4.jpg'
import bg1 from '../assests/bg.jpg'
import bg3 from '../assests/bg2.jpg'
import bg4 from '../assests/bg4.jpg'



export const Home = () => {
  return (
    <div className={styles.main_container}>
      <nav className={styles.navbar}>
        <h1>Job360</h1>
        <Link to='/login'>
          <button className={`${styles.white_btn} ${styles.btn}`}>LogIn</button>
        </Link>
        <Link to='/signup'>
          <button className={styles.white_btn}>Signup</button>
        </Link>
      </nav>
      <div className={styles.background_section}>
        <div className={styles.content_wrapper}>
          <h1>Welcome to the Ultimate Career Hub </h1>
          <p>Welcome to the ultimate career hub where you can access everything you need in one place! Our platform offers a comprehensive suite of tools to support your professional journey.</p>
          <button className={styles.learn_more_btn}>Know More</button>
        </div>
        <div className={styles.image_container}>
          <img src={bg} alt="Career Resources" />
        </div>
      </div>
      <h1 className='h1home'>Get To Explore</h1>
      <div className={styles.card_section}>
       
        <div className={styles.card}>
          <img src={bg1} alt="Resume Builder" className={styles.card_image} />
          <h3>Resume Builder</h3>
          <p>Create a professional resume with our easy-to-use resume builder.</p>
        </div>
        <div className={styles.card}>
          <img src={bg4} alt="Job Portal" className={styles.card_image} />
          <h3>Job Portal</h3>
          <p>Explore job opportunities from top companies in one place.</p>
        </div>
        <div className={styles.card}>
          <img src={bg3} alt="Video Courses" className={styles.card_image} />
          <h3>Video Courses</h3>
          <p>Access video courses to learn the latest skills at your own pace.</p>
        </div>
        <div className={styles.card}>
          <img src={bg4}alt="Quizzes" className={styles.card_image} />
          <h3>Quizzes</h3>
          <p>Test your knowledge and track progress with interactive quizzes.</p>
        </div>
      </div>
    </div>
  );
};
