import React, { useEffect, useState } from "react";
import styles from './styles.module.css';
import axios from "axios";
import TemplateOne from "../Templates/TemplateOne";
import TemplateTwo from '../Template2/TempleteTwo'
import TemplateThree from '../Template3/TemplateThree';
import html2pdf from 'html2pdf.js'; 
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const [data, setData] = useState({});
    const [template, setTemplate] = useState("TemplateOne");
    const navigate=useNavigate('')

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    useEffect(() => {
        const fetchdata = async () => {
            const token = localStorage.getItem("token");
            const config = {
                headers: {
                    "x-auth-token": token
                }
            };
            try {
                const personalRes = await axios.get("http://localhost:5000/api/personal/personal", config);
                const conatactRes = await axios.get("http://localhost:5000/api/contact/contact", config);
                const skillRes = await axios.get("http://localhost:5000/api/skill/skill", config);
                const experienceRes = await axios.get("http://localhost:5000/api/experience/experience", config);
                const educationRes = await axios.get("http://localhost:5000/api/education/education", config);
                const activitiesRes = await axios.get("http://localhost:5000/api/activities/activities", config);
                const awardsRes = await axios.get("http://localhost:5000/api/awards/awards", config);

                setData({
                    personal: personalRes.data,
                    contact: conatactRes.data,
                    skill: skillRes.data[0]?.skills || [],
                    experience: experienceRes.data || [],
                    education: educationRes.data || [],
                    activities: activitiesRes.data,
                    awards: awardsRes.data
                });
            } catch (error) {
                console.log("Error in fetching data @ dashboard page", error);
            }
        };
        fetchdata();
    }, []);

    const handleTemplateChange = (templateName) => {
        setTemplate(templateName);
    };

    // const downloadResume = () => {
    //     const element = document.getElementById('resume-template'); // The resume content container
        
    //     // Configure html2pdf settings
    //     const opt = {
    //         margin: 1,
    //         filename: 'resume.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 2, useCORS: true },
    //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //         pagebreak: { mode: ['avoid-all', 'css', 'legacy'] } // Ensures page breaks are respected
    //     };
        
    //     // Generate and download the PDF
    //     html2pdf().from(element).set(opt).save();
    // };
    const downloadResume = () => {
        const element = document.getElementById('resume-template'); 
    
       
        const noPrintElements = document.querySelectorAll('.no-print');
        noPrintElements.forEach(el => el.style.display = 'none');
    
        const opt = {
            margin: 1,
            filename: 'resume.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };
    
        html2pdf()
            .from(element)
            .set(opt)
            .save()
            .then(() => {
                
                noPrintElements.forEach(el => el.style.display = 'block');
            });
    };
    return (
        <>
            <div className={styles.main_container}>
                <nav className={styles.navbar}>
                    <h1>Resume Builder</h1>
                    <button className={styles.white_btn} onClick={handleLogout}>
                        Logout
                    </button>
                </nav>
            </div>
            <div className={styles.dashboard_container}>
                <h1>Choose Your Template</h1>
                <div className={styles.template_buttons}>
                    <button onClick={() => handleTemplateChange("TemplateOne")}>Visionary</button>
                    <button onClick={() => handleTemplateChange("TemplateTwo")}>Lattice</button>
                    <button onClick={() => handleTemplateChange("TemplateThree")}>Eclipse</button>
                    <button onClick={downloadResume} className={styles.download_btn}>Download Resume</button>
                </div>
                <div className={styles.resume_overlay}>
                    <div className={styles.resume_display} id="resume-template">
                        {template === "TemplateOne" && <TemplateOne data={data} />}
                        {template === "TemplateTwo" && <TemplateTwo data={data} />}
                        {template === "TemplateThree" && <TemplateThree data={data} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
