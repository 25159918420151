import React from "react";
import styles from './styles.module.css';
import { useState } from "react";

const TemplateOne = ({ data }) => {
    const [selectedColor, setSelectedColor] = useState("#000000"); 

    const handleColorChange = (color) => {
        setSelectedColor(color); 
    };
    console.log("Data in TemplateOne:", data);
    console.log("Personal Info Data:", data.personal);
    console.log("First name:", data.personal?.name);
    if (!data.personal) return null;

    return (
        <>
        <div className={`${styles.colorSelector} no-print`}>
                <button className={styles.red} onClick={() => handleColorChange("#ff6347")}></button>
                <button className={styles.blue} onClick={() => handleColorChange("#001367")}></button>
                <button className={styles.voilet} onClick={() => handleColorChange("#710077")}></button>
                <button className={styles.grey} onClick={() => handleColorChange("#545454")}></button>
        </div>
        <div className={styles.templateOneContainer} style={{ '--main-color': selectedColor }}>
        <div className={styles.templateOne}>
            <div className={styles.sidebar}>
            <img src={`http://localhost:5000/uploads/${data.personal?.imagePath}`} alt="Profile" height="175px" width="175px"
             className={styles.personalimg}
             crossOrigin="anonymous" 
             onLoad={() => console.log("Image loaded")} 
             />
            {/* <p>{data.personal.profession}</p> */}
            <section>
                    <h3>Education</h3>
                    <ul>
                    {data.education.map((edu, index) => (
                        <li key={index}><sapn>{edu.institution}</sapn>
                            <p>{edu.degree} {edu.discipline}</p>
                            <p></p>
                            <p>{new Date(edu.startDate).toLocaleDateString()} - {new Date(edu.endDate).toLocaleDateString()}</p>
                        </li>
                    ))}
                    </ul>
                </section>

                <div className={styles.skills}>
                    <h3>Skills</h3>
                   <ul>
                        {data.skill.map((ski, index) => (
                            <li key={index}>{ski.skill} - {ski.level}</li>
                        ))}
                   </ul>
                </div>
                <div className={styles.contactInfo}>
                    <h3>Contact</h3>
                    <p>{data.contact.github}</p>
                    <p>{data.contact.linkedin}</p>
                    <p>{data.contact.phone}</p>
                    <p>{data.contact.area},{data.contact.location}</p>
                </div>

                <div className={styles.language}>
                    <h3>Language</h3>
                    <p>English</p>
                    <p>Tamil</p>
                </div>
            </div>
            <div className={styles.mainContent}>
                <section>
                <h2 className={styles.head}>{data.personal?.firstName}</h2>
                <p className={styles.head_description}>{data.education[0]?.degree} {data.education[0]?.discipline}</p>
                <p>{data.personal.description}</p>
                    <h3>Experience</h3>
                    <ul>
                    {data.experience.map((exp, index) => (
                        <li key={index}><span className={styles.span}>{exp.company}</span>
                            <p>{exp.position}</p>
                            <p>{new Date(exp.startDate).toLocaleDateString()} - {new Date(exp.endDate).toLocaleDateString()}</p>
                            <p>{exp.description}</p>
                        </li>
                    ))}
                    </ul>
                </section>
                <section>
                    <h3>Awards</h3>
                    <ul>
                    {data.awards.map((awa, index) => (
                        <li key={index}><sapn className={styles.span}>{awa.title}</sapn>
                            <p>Given by {awa.awardby} on {new Date(awa.date).toLocaleDateString()} </p>
                            <p></p>
                            <p>{awa.description}</p>
                        </li>
                    ))}
                    </ul>
                </section>
                <section className={styles.activities} >
                    <h3>Activities</h3>
                    <ul>
                        {data.activities.map((act, index) => (
                            <li key={index}>
                                <h4 className={styles.span}>{act.title}</h4>
                                <p>{act.description}</p>
                            </li>
                        ))}
                    </ul>
                </section>
            </div>
        </div>
        </div>
        </>
    );
};

export default TemplateOne