// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_job_list_container__TMQnc {
    padding: 2rem;
}

.styles_job_cards__IJKX2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 2rem; 
    justify-items: center;
}

.styles_job_card__3zDAd {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    width: 100%; 
    max-width: 450px; 
    text-align: left;
    transition: transform 0.3s ease;
    box-shadow:1px 1px 20px 1px #19b394;
}

.styles_job_card__3zDAd:hover {
    transform: scale(1.02);
}

.styles_job_card__3zDAd h2 {
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
    color: #333;
}

.styles_job_card__3zDAd p {
    font-size: 0.95rem;
    margin: 0.25rem 0;
    color: #555;
}

ul {
    padding-left: 1.25rem;
}
h1{
    text-align: center;
    color:#000000 ;
}
`, "",{"version":3,"sources":["webpack://./src/Components/JobList/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,wCAAwC;IACxC,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,+BAA+B;IAC/B,mCAAmC;AACvC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".job_list_container {\r\n    padding: 2rem;\r\n}\r\n\r\n.job_cards {\r\n    display: grid;\r\n    grid-template-columns: repeat(3, 1fr); \r\n    gap: 2rem; \r\n    justify-items: center;\r\n}\r\n\r\n.job_card {\r\n    background: #fff;\r\n    border-radius: 8px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    padding: 1.5rem;\r\n    width: 100%; \r\n    max-width: 450px; \r\n    text-align: left;\r\n    transition: transform 0.3s ease;\r\n    box-shadow:1px 1px 20px 1px #19b394;\r\n}\r\n\r\n.job_card:hover {\r\n    transform: scale(1.02);\r\n}\r\n\r\n.job_card h2 {\r\n    font-size: 1.7rem;\r\n    margin-bottom: 0.5rem;\r\n    color: #333;\r\n}\r\n\r\n.job_card p {\r\n    font-size: 0.95rem;\r\n    margin: 0.25rem 0;\r\n    color: #555;\r\n}\r\n\r\nul {\r\n    padding-left: 1.25rem;\r\n}\r\nh1{\r\n    text-align: center;\r\n    color:#000000 ;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"job_list_container": `styles_job_list_container__TMQnc`,
	"job_cards": `styles_job_cards__IJKX2`,
	"job_card": `styles_job_card__3zDAd`
};
export default ___CSS_LOADER_EXPORT___;
