import styles from './styles.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';

const Signup=()=>{
    const[data,setdata]=useState({
        firstname:"",
        phonenumber:"",
        email:"",
        password:""
    });
     const[error,setError]=useState('')
     const navigate=useNavigate();


    const handleChange=({currentTarget:input})=>{
        setdata({...data,[input.name]:input.value})
    };
   
    const handleSubmit=async(e)=>{
        console.log("slkdfs");  
       e.preventDefault();
       console.log(data);
       try{
           const url="http://localhost:5000/api/users";
           const{data:res}=await axios.post(url,data);
           navigate('/login')
           console.log(res.message);
       }
       catch(error){
           if(error.response && error.response.status>=400 && error.response.status<=500){
               setError(error.response.data.message);
           }
       }
    }
     
    return(
     <div className={styles.signup_container}>
         <div className={styles.signup_form_container}>
           <div className={styles.left}>
            <h1>Welcome Back</h1>
             <Link to="/login">
             <button type='button' className={styles.white_btn}>
                Sign In
             </button>
             </Link>
           </div>
           <div className={styles.right}>
            <form className={styles.form_container} onSubmit={handleSubmit}>
              <h1>Create Account</h1>
              <input
                  type='text'
                  placeholder='FirstName'
                  name="firstname"
                  value={data.firstname}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
              <input
                  type='text'
                  placeholder='PhoneNumber'
                  name="phonenumber"
                  value={data.phonenumber}
                  onChange={handleChange}
                  required
                  className={styles.input}
                  maxLength="10"
                  pattern="\d{10}"
              />
               <input
                  type='email'
                  placeholder='Email'
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
               <input
                  type='password'
                  placeholder='Password'
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                  required
                  className={styles.input}
              />
              {error && <div className={styles.error_msg}>{error}</div>}
              <button type="submit" className={styles.green_btn}>
                Sign Up
              </button>
            </form>
           </div>
         </div>
     </div>

    )
};
export default Signup;