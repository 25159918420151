import Signup from '../src/Components/Signup/index';
import { Route,Routes,Navigate } from 'react-router-dom';
import LogIn from './Components/Login/index.jsx';
import Main from './Components/Main/Index.jsx';
import Contact from './Components/Contact/contact.jsx';
import Skill from './Components/Skills/Skill.jsx';
import Education from './Components/Education/Education.jsx';
import Experience from './Components/Experience/Experience.jsx';
import Activities from './Components/Activities/Activities.jsx';
import Awards from './Components/Awards/Awards.jsx'
import Dashboard from './Components/Dashboard/Dashboard.jsx';
import MainPage from './Components/MainPage/MainPage.jsx';
import JobForm from './Components/JobForm/JobForm.jsx';
import JobList from './Components/JobList/JobList.jsx';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute.jsx';
import {Home} from './Components/Home/Home.jsx';

function App() {
  const user=localStorage.getItem('token')
  return (
    <Routes>
      {user && <Route path='/personal' exact element={<Main/>}></Route>}
      <Route path='/signup' exact element={<Signup/>}></Route>
      <Route path='/login' exact element={<LogIn/>}></Route>
      <Route path='/contact' exact element={<Contact/>}></Route>
      <Route path='/skill' exact element={<Skill/>}></Route>
      <Route path='/education' exact element={<Education/>}></Route>
      <Route path='/experience' exact element={<Experience/>}></Route>
      <Route path='/activities' exact element={<Activities/>}></Route>
      <Route path='/awards' exact element={<Awards/>}></Route>
      <Route path='/preview' exact element={<Dashboard/>}></Route>
      <Route path="/dashboard" element={
                    <PrivateRoute allowedRoles={['user','admin']}>
                       <MainPage/>
                    </PrivateRoute>
                } />
      <Route path='/addjob' exact element={<JobForm/>}></Route>
      <Route path='/job' exact element={<JobList/>}></Route>
      <Route path='/homepage' exact element={<Home/>}></Route>
      {/* <Route path='/'  element={<Navigate replace to="/login" />}></Route> */}
      <Route path='/' element={<Navigate replace to="/homepage" />} />
    </Routes>
  );
}

export default App;
