import styles from './styles.module.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Education = () => {
    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    };

    const [educations, setEducations] = useState([
        { institution: 'Top Skilled', degree: 'MSC', discipline: 'THAT', grade: 'A', startDate: '2021-02-20', endDate: '2024-02-20', location: 'THIS' }
    ]);
    const [error, setError] = useState('');
    const navigate = useNavigate('');

   
    const handleEducationChange = (index, e) => {
        const updatedEducations = [...educations];
        updatedEducations[index][e.target.name] = e.target.value;
        setEducations(updatedEducations);
    };

  
    const handleAddEducation = () => {
        setEducations([...educations, { institution: '', degree: '', discipline: '', grade: '', startDate: '', endDate: '', location: '' }]);
    };

    const handleRemoveEducation = (index) => {
        const updatedEducations = educations.filter((_, i) => i !== index);
        setEducations(updatedEducations);
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("gfgfhfgf")
        console.log(educations)
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    "x-auth-token": token
                }
            };
            const url ="http://localhost:5000/api/education/educationinfo";
            await axios.post(url, { educations }, config);
            alert("Education details saved successfully");
            navigate('/experience');
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                setError(error.response.data.message);
            }
        }
    };

    return (
        <>
            <div className={styles.main_container}>
                <nav className={styles.navbar}>
                    <h1>Resume Builder</h1>
                    <button className={styles.white_btn} onClick={handleLogout}>
                        Logout
                    </button>
                </nav>
            </div>
            <div className={styles.signup_container}>
                <div className={styles.left}></div>
                <form onSubmit={handleSubmit} className={styles.form_container}>
                    <h1>Add Your Education</h1>
                    {educations.map((education, index) => (
                        <div key={index} className={styles.education_container}>
                            <input
                                type="text"
                                name="institution"
                                placeholder="Institution Name"
                                value={education.institution}
                                onChange={(e) => handleEducationChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="text"
                                name="degree"
                                placeholder="Degree"
                                value={education.degree}
                                onChange={(e) => handleEducationChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="text"
                                name="discipline"
                                placeholder="Discipline"
                                value={education.discipline}
                                onChange={(e) => handleEducationChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="text"
                                name="grade"
                                placeholder="Grade"
                                value={education.grade}
                                onChange={(e) => handleEducationChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="date"
                                name="startDate"
                                placeholder="Start Date"
                                value={education.startDate}
                                onChange={(e) => handleEducationChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="date"
                                name="endDate"
                                placeholder="End Date"
                                value={education.endDate}
                                onChange={(e) => handleEducationChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="text"
                                name="location"
                                placeholder="Location"
                                value={education.location}
                                onChange={(e) => handleEducationChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveEducation(index)}
                                className={styles.delete_btn}
                            >
                                Delete
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddEducation} className={styles.add_btn}>
                        Add More Education
                    </button>
                    {error && <div className={styles.error_msg}>{error}</div>}
                    <button type="submit" className={styles.submit_btn}>
                        Save Education
                    </button>
                </form>
            </div>
        </>
    );
};

export default Education;
