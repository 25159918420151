import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, allowedRoles }) => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    // Check if user is authenticated (has a token)
    if (!token) {
        return <Navigate to="/login" replace />; // Redirect to login if no token
    }

    // Check if user has the correct role
    if (allowedRoles && !allowedRoles.includes(role)) {
        return <Navigate to="/unauthorized" replace />; // Redirect to unauthorized page if role is invalid
    }

    // If authenticated and has correct role, render children
    return children;
};

export default PrivateRoute;


// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const PrivateRoute = ({ children, allowedRoles }) => {
//     const role = localStorage.getItem("role");

//     if (!allowedRoles.includes(role)) {
//         return <Navigate to="/unauthorized" />; 
//     }

//     return children; 
// };

// export default PrivateRoute;