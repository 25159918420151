import React from 'react'
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
// import Main from '../Main/Index';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bg4 from '../assests/bg4.jpg'
import bg2 from '../assests/bg2.jpg'
import bg1 from '../assests/bg1.webp'



function MainPage() {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
    <div className={styles.main_container}>
			<nav className={styles.navbar}>
				<h1>Job360</h1>
                <Link to='/personal'>
                    <button className={`${styles.white_btn} ${styles.btn}`}>Resume Builder</button>
                </Link>
                <Link to='/job'>
                    <button className={styles.white_btn}>Find Your Job</button>
                </Link>
                <Link to='/#'>
                    <button className={styles.white_btn}>Start Your quiz</button>
                </Link>
                <Link to='/#'>
                    <button className={styles.white_btn}>Tutorials</button>
                </Link>
				<button className={styles.white_btn} >
					Logout
				</button>
			</nav>
		</div> 
    <div className={styles.carousel_section}>
        <Slider {...carouselSettings}>
          <div className={styles.carousel_slide}>
            <img src={bg1} alt="Slide 1" className={styles.carousel_image} />
            <div className={styles.carousel_content}>
              <h2 className={styles.carousel_head} >Build Your Perfect Resume</h2>
              <p>Craft a resume that stands out! Our easy-to-use resume builder helps you design a professional and customized resume tailored to your skills, experience, and career goals. With pre-made templates and step-by-step guidance, you’ll have a polished, eye-catching resume ready to impress potential employers.</p>
            </div>
          </div>
          <div className={styles.carousel_slide}>
            <img src={bg2} alt="Slide 2" className={styles.carousel_image} />
            <div className={styles.carousel_content}>
              <h2>Find the Best Job Matches</h2>
              <p>Discover job opportunities that truly match your unique skills and interests. Our platform offers personalized job recommendations based on your profile, making it easier than ever to connect with employers looking for candidates like you. Get closer to your dream career with curated job listings tailored just for you."</p>
            </div>
          </div>
          <div className={styles.carousel_slide}>
            <img src={bg4} alt="Slide 3" className={styles.carousel_image} />
            <div className={styles.carousel_content}>
              <h2>Learn New Skills</h2>
              <p>Stay ahead in your career by learning the latest industry skills. With our comprehensive video courses, quizzes, and interactive content, you can gain valuable knowledge and enhance your abilities. From technical skills to soft skills, unlock a world of learning to elevate your professional profile."</p>
            </div>
          </div>
        </Slider>
      </div>
    </>
  )
}

export default MainPage