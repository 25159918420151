import React from "react";
import styles from './styles.module.css'
import { useState } from "react";

const InfographicTemplate = ({ data }) => {
    const [selectedColor, setSelectedColor] = useState("#000000");

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };
    if (!data.personal) return null;

    return (
        <>
        {/* Color Picker */}
        <div className={`${styles.colorSelector} no-print`}>
                <button className={styles.red} onClick={() => handleColorChange("#ff6347")}></button>
                <button className={styles.blue} onClick={() => handleColorChange("#001367")}></button>
                <button className={styles.voilet} onClick={() => handleColorChange("#710077")}></button>
                <button className={styles.grey} onClick={() => handleColorChange("#545454")}></button>
            </div>
        <div className={styles.templateContainer}  style={{ "--main-color": selectedColor }}>
            {/* Header Section */}
            <header className={styles.header}>
                <div className={styles.profile}>
                    <img
                        src={`http://localhost:5000/uploads/${data.personal?.imagePath}`}
                        alt="Profile"
                        className={styles.profileImage}
                        crossOrigin="anonymous"
                    />
                    <div className={styles.personalInfo}>
                        <h1>{data.personal.firstName} {data.personal.lastName}</h1>
                        <h3>{data.education[0]?.degree} in {data.education[0]?.discipline}</h3>
                        <p>{data.personal.description}</p>
                    </div>
                </div>
            </header>

            {/* Main Content */}
            <main className={styles.mainContent}>
                {/* Key Skills Section */}
                <section className={styles.section}>
                    <h2>Key Skills</h2>
                    <div className={styles.skills}>
                        {data.skill.map((ski, index) => (
                            <div key={index} className={styles.skill}>
                                <span>{ski.skill}</span>
                                <span className={styles.skillLevel}>({ski.level})</span>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Education Section */}
                <section className={styles.edusection}>
                    <h2>Education</h2>
                    <div className={styles.timeline}>
                        {data.education.map((edu, index) => (
                            <div key={index} className={styles.timelineItem}>
                                <h3>{edu.institution}</h3>
                                <p>{edu.degree} in {edu.discipline}</p>
                                <span>{new Date(edu.startDate).getFullYear()} - {new Date(edu.endDate).getFullYear()}</span>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Experience Section */}
                <section className={styles.expsection}>
                    <h2>Experience</h2>
                    <div className={styles.cards}>
                        {data.experience.map((exp, index) => (
                            <div key={index} className={styles.card}>
                                <h3>{exp.position}</h3>
                                <h4>{exp.company}</h4>
                                <p>{exp.description}</p>
                                <span>{new Date(exp.startDate).toLocaleDateString()} - {new Date(exp.endDate).toLocaleDateString()}</span>
                            </div>
                        ))}
                    </div>
                </section>
                {/* Activities Section */}
                <section className={styles.actsection}>
                        <h2>Activities</h2>
                        <ul>
                            {data.activities.map((act, index) => (
                                <li key={index}>
                                    <h3>{act.title}</h3>
                                    <p>{act.description}</p>
                                </li>
                            ))}
                        </ul>
                    </section>

                {/* Awards Section */}
                <section className={styles.awdsection}>
                    <h2>Awards</h2>
                    <ul className={styles.awardsList}>
                        {data.awards.map((awa, index) => (
                            <li key={index}>
                                <strong>{awa.title}</strong> - {awa.awardby} ({new Date(awa.date).toLocaleDateString()})
                            </li>
                        ))}
                    </ul>
                </section>
                
            </main>
        </div>
        </>
    );
};

export default InfographicTemplate;
