import React, { useState } from "react";
import axios from "axios";
import styles from './styles.module.css';

const JobForm = () => {
  const [jobDataList, setJobDataList] = useState([{
    title: 'Full stack Developer',
    company_name: 'Top Skilled Technologies',
    location: 'pondy',
    description: 'write in 10 lines',
    employement_type: 'Hybrid',
    experience_level: '1 to 2 YRS',
    skills_required: ['Web Design']
  }]);

  const handleChange = (e, index) => {
    const updatedJobDataList = [...jobDataList];
    updatedJobDataList[index][e.target.name] = e.target.value;
    setJobDataList(updatedJobDataList);
  };


  const addNewJobForm = () => {
    setJobDataList([
      ...jobDataList,
      {
        title: 'Full stack Developer',
    company_name: 'Top Skilled Technologies',
    location: 'pondy',
    description: 'write in 10 lines',
    employement_type: 'Hybrid',
    experience_level: '1 to 2 YRS',
    skills_required: ['Web Design']
      }
    ]);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      for (let jobData of jobDataList) {
        await axios.post('http://localhost:5000/api/jobs/jobinfo', jobData);
      }
      alert("All Jobs Saved Successfully");
      
      setJobDataList([{
        title: '',
        company_name: '',
        location: '',
        description: '',
        employement_type: '',
        experience_level: '',
        skills_required: ['']
      }]);
    } catch (error) {
      console.log("Error Saving Job Data", error);
      alert("Error saving job data");
    }
  };


  const handleDelete = (index) => {
    const updatedJobDataList = [...jobDataList];
    updatedJobDataList.splice(index, 1); 
    setJobDataList(updatedJobDataList);
  };


  const handleSkillsChange = (jobIndex, skillIndex, value) => {
    const updatedJobDataList = [...jobDataList];
    const updatedSkills = [...updatedJobDataList[jobIndex].skills_required];
    updatedSkills[skillIndex] = value;
    updatedJobDataList[jobIndex].skills_required = updatedSkills;
    setJobDataList(updatedJobDataList);
  };

  
  const addSkillField = (jobIndex) => {
    const updatedJobDataList = [...jobDataList];
    updatedJobDataList[jobIndex].skills_required.push(''); 
    setJobDataList(updatedJobDataList);
  };

  return (
    <>
    <h1>Add Jobs</h1>
    <div className={styles.job_container}>   
      <form onSubmit={handleSubmit} className={styles.form_container}>
        {jobDataList.map((jobData, jobIndex) => (
          <div key={jobIndex} className={styles.job_form_section}>
            <h3>Job {jobIndex + 1}</h3>
            <input
              type="text"
              name="title"
              placeholder="Job Title"
              value={jobData.title}
              onChange={(e) => handleChange(e, jobIndex)}
              className={styles.input}
              required
            />
            <input
              type="text"
              name="company_name"
              placeholder="Company Name"
              value={jobData.company_name}
              onChange={(e) => handleChange(e, jobIndex)}
              className={styles.input}
              required
            />
            <input
              type="text"
              name="location"
              placeholder="Location"
              value={jobData.location}
              onChange={(e) => handleChange(e, jobIndex)}
              className={styles.input}
              required
            />
            <input
              type="text"
              name="description"
              placeholder="Job Description"
              value={jobData.description}
              onChange={(e) => handleChange(e, jobIndex)}
              className={styles.input}
              required
            />
            <input
              type="text"
              name="employement_type"
              placeholder="Employment Type"
              value={jobData.employement_type}
              onChange={(e) => handleChange(e, jobIndex)}
              className={styles.input}
              required
            />
            <input
              type="text"
              name="experience_level"
              placeholder="Experience Level"
              value={jobData.experience_level}
              onChange={(e) => handleChange(e, jobIndex)}
              className={styles.input}
              required
            />
            
           
            {jobData.skills_required.map((skill, skillIndex) => (
              <div key={skillIndex} className={styles.skill_input}>
                <input
                  type="text"
                  name="skills_required"
                  placeholder="Skill"
                  value={skill}
                  onChange={(e) => handleSkillsChange(jobIndex, skillIndex, e.target.value)}
                  className={styles.input}
                  required
                />
              </div>
            ))}

          
            <button
              type="button"
              onClick={() => addSkillField(jobIndex)}
              className={styles.add_btn}
            >
              Add Skill
            </button>

          
            <button
              type="button"
              onClick={() => handleDelete(jobIndex)}
              className={styles.delete_btn}
            >
              Delete Job
            </button>
          </div>
        ))}

        <button type="button" onClick={addNewJobForm} className={styles.add_btn}>
          Add More Job
        </button>

        <button type="submit" className={styles.submit_btn}>Save All Jobs</button>
      </form>
    </div>
    </>
  );
};

export default JobForm;