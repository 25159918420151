import styles from './styles.module.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Skill = () => {
    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    const [skills, setSkills] = useState([{ skill: 'python', level: 50 }]);
    const [error, setError] = useState('');
    const navigate = useNavigate('');

    const handleSkillChange = (index, e) => {
        const updatedSkills = [...skills];
        updatedSkills[index][e.target.name] = e.target.value;
        setSkills(updatedSkills);
    };

    const handleAddSkill = () => {
        setSkills([...skills, { skill: '', level: 50 }]);
    };

    const handleRemoveSkill = (index) => {
        const updatedSkills = skills.filter((_, i) => i !== index);
        setSkills(updatedSkills);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    "x-auth-token": token
                }
            };
            const url = "http://localhost:5000/api/skill/skillinfo";
            await axios.post(url, { skills }, config);
            alert("Skills saved successfully");
            navigate('/education');
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                setError(error.response.data.message);
            }
        }
    };

    return (
        <>
            <div className={styles.main_container}>
                <nav className={styles.navbar}>
                    <h1>Resume Builder</h1>
                    <button className={styles.white_btn} onClick={handleLogout}>
                        Logout
                    </button>
                </nav>
            </div>
            <div className={styles.signup_container}>
                <div className={styles.left}>
                 
                </div>
                <form onSubmit={handleSubmit} className={styles.form_container}>
                <h1>Add Your Skills</h1>
                    {skills.map((skillItem, index) => (
                        <div key={index} className={styles.skill_container}>
                            <input
                                type="text"
                                name="skill"
                                placeholder="Skill Name"
                                value={skillItem.skill}
                                onChange={(e) => handleSkillChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <label>Proficiency Level: {skillItem.level}</label>
                            <input
                                type="range"
                                name="level"
                                min="0"
                                max="100"
                                step='10'
                                value={skillItem.level}
                                onChange={(e) => handleSkillChange(index, e)}
                                className={styles.range_input}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveSkill(index)}
                                className={styles.delete_btn}
                            >
                                Delete
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddSkill} className={styles.add_btn}>
                        Add More Skills
                    </button>
                    {error && <div className={styles.error_msg}>{error}</div>}
                    <button type="submit" className={styles.submit_btn}>
                        Save Skills
                    </button>
                </form>
            </div>
        </>
    );
};

export default Skill;
