import React from "react";
import { useState } from "react";
import styles from './styles.module.css'
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Activities=()=>{
    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };
  const[activities,setActivities]=useState([{title:"Team Leader", description:"i am am team leader in my previous company..."}]);
  const[error,setError]=useState('');
  const navigate=useNavigate('');


  const handleActivitiesChange=(index, e)=>{
    const updatedActivities=[...activities];
    updatedActivities[index][e.target.name]=e.target.value;
    setActivities(updatedActivities)
  }

  const handleAddActivities=()=>{
    setActivities([...activities, {title:' ',description:' '}]);
  }

  const handleRemoveActivities=(index)=>{
    const updatedActivities=activities.filter((_,i)=>i!==index);
    setActivities(updatedActivities)

  }
  const handleSubmit=async(e)=>{
    console.log("hghjghj")
    e.preventDefault();
    console.log(activities);
    try{
        const token=localStorage.getItem('token');
        const config={
            headers:{
                "x-auth-token": token
            }
        };
        const url="http://localhost:5000/api/activities/activitiesinfo"
        await axios.post(url,{activities},config)
        alert("Activities Data saved Successfully")
        navigate('/awards')
    }
    catch(error){
        if (error.response && error.response.status >= 400 && error.response.status <= 500) {
            setError(error.response.data.message);
        }
    }
  }


    return(
        <>
         <div className={styles.main_container}>
                <nav className={styles.navbar}>
                    <h1>Resume Builder</h1>
                    <button className={styles.white_btn} onClick={handleLogout}>
                        Logout
                    </button>
                </nav>
            </div>
            <div className={styles.signup_container}>
                <div className={styles.left}></div>
                <form onSubmit={handleSubmit} className={styles.form_container}>
                    <h1>Add Your Activities</h1>
                    {activities.map((activities, index) => (
                        <div key={index} className={styles.activities_container}>
                            <input
                                type="text"
                                name="title"
                                placeholder="title"
                                value={activities.title}
                                onChange={(e) => handleActivitiesChange(index, e)}
                                required
                                className={styles.input}
                            />
                             <textarea 
                            name="description" 
                            type="text"
                            placeholder="Description"
                            value={activities.description}
                            onChange={(e) => handleActivitiesChange(index, e)}
                            required
                            className={styles.input}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveActivities(index)}
                                className={styles.delete_btn}
                            >Delete</button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddActivities} className={styles.add_btn}>
                        Add More Activities
                    </button>
                    {error && <div className={styles.error_msg}>{error}</div>}
                    <button type="submit" className={styles.submit_btn}>
                        Save Activities
                    </button>
                </form>
            </div>
        
        </>
    )
}

export default Activities