import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './styles.module.css';

const JobList = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/jobs/jobs');
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    fetchJobs();
  }, []);

  return (
    <div className={styles.job_list_container}>
      <h1>Job List</h1>
      <div className={styles.job_cards}>
        {jobs.map((job, index) => (
          <div key={index} className={styles.job_card}>
            <h2>{job.title}</h2>
            <p><strong>Company:</strong> {job.company_name}</p>
            <p><strong>Location:</strong> {job.location}</p>
            <p><strong>Description:</strong> {job.description}</p>
            <p><strong>Employment Type:</strong> {job.employement_type}</p>
            <p><strong>Experience Level:</strong> {job.experience_level}</p>
            <p><strong>Skills Required:</strong></p>
            <ul>
              {job.skills_required.map((skill, skillIndex) => (
                <li key={skillIndex}>{skill}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobList;
