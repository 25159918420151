import React from "react";
import styles from './styles.module.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Experience=()=>{
    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    const [experiences, setExperience] = useState([
        { company: 'Top Skilled', position: 'Software Developer', startDate: '2021-02-20', endDate: '2024-02-20', responsibilities:'THIS',description:"sample" }
    ]);
    const [error, setError] = useState('');
    const navigate = useNavigate('');

   
    const handleExperienceChange = (index, e) => {
        const updatedExperience = [...experiences];
        updatedExperience[index][e.target.name] = e.target.value;
        setExperience(updatedExperience);
    };

  
    const handleAddExperience = () => {
        setExperience([...experiences, {company: ' ', position: ' ', startDate: '', endDate: '', responsibilities: '',description:"" }]);
    };

    const handleRemoveExperience = (index) => {
        const updatedExperience = experiences.filter((_, i) => i !== index);
        setExperience(updatedExperience);
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("gfgfhfgf")
        console.log(experiences)
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    "x-auth-token": token
                }
            };
            const url ="http://localhost:5000/api/experience/experienceinfo";
            await axios.post(url, { experiences }, config);
            alert("Experience details saved successfully");
            navigate('/activities');
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                setError(error.response.data.message);
            }
        }
    };

    return (
        <>
            <div className={styles.main_container}>
                <nav className={styles.navbar}>
                    <h1>Resume Builder</h1>
                    <button className={styles.white_btn} onClick={handleLogout}>
                        Logout
                    </button>
                </nav>
            </div>
            <div className={styles.signup_container}>
                <div className={styles.left}></div>
                <form onSubmit={handleSubmit} className={styles.form_container}>
                    <h1>Add Your Experience</h1>
                    {experiences.map((experience, index) => (
                        <div key={index} className={styles.experience_container}>
                            <input
                                type="text"
                                name="company"
                                placeholder="Comapny name"
                                value={experience.company}
                                onChange={(e) => handleExperienceChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="text"
                                name="position"
                                placeholder="Position"
                                value={experience.position}
                                onChange={(e) => handleExperienceChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="date"
                                name="startDate"
                                placeholder="Start Date"
                                value={experience.startDate}
                                onChange={(e) => handleExperienceChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="date"
                                name="endDate"
                                placeholder="End Date"
                                value={experience.endDate}
                                onChange={(e) => handleExperienceChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <input
                                type="text"
                                name="responsibilities"
                                placeholder="Responsibilities"
                                value={experience.responsibilities}
                                onChange={(e) => handleExperienceChange(index, e)}
                                required
                                className={styles.input}
                            />
                            <textarea 
                            name="description" 
                            type="text"
                            placeholder="Description"
                            value={experience.description}
                            onChange={(e) => handleExperienceChange(index, e)}
                            required
                            className={styles.input}
                            />
                            
                            <button
                                type="button"
                                onClick={() => handleRemoveExperience(index)}
                                className={styles.delete_btn}
                            >Delete</button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddExperience} className={styles.add_btn}>
                        Add More Experience
                    </button>
                    {error && <div className={styles.error_msg}>{error}</div>}
                    <button type="submit" className={styles.submit_btn}>
                        Save Experieence
                    </button>
                </form>
            </div>
        </>
    );
}
export default Experience;
